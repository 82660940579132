
.image-grid {
	--gap: 16px;
	--num-cols:4; 
	--row-height: 300px;

	box-sizing: border-box;
	padding: var(--gap);

	display: grid;
	grid-template-columns: repeat(var(--num-cols), 1fr);
	grid-auto-rows: var(--row-height);
	gap: var(--gap);
  
}

.image-grid>img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 20px;
	transition: all 0.5s;
}
.image-grid>img:hover{
	transform: scale(1.05);
}

.image-grid-col-2 {
	grid-column: span 2;
}

.image-grid-row-2 {
	grid-row: span 2;
}

/* Anything udner 1024px */
@media screen and (max-width: 1024px) {
	.image-grid {
		--num-cols: 2;
		--row-height: 200px;
	}
}